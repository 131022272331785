import revive_payload_client_iDETYPv4MC from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b2UWYo84sl from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Nh8L56SyFT from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZBlUIYAmBb from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3lyIqAzLiu from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kbriyrF8Hi from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sSDI4m3RX4 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_f7Kkr6tkyO from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/centre-supervisor-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_y2bXzdP3Kh from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_drizzle-orm@0.33.0_@types+pg@8.11.10_n6wbvhnoyge4mqggpbvmeqqf5i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_p9KGT4uXma from "/app/centre-supervisor-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.28.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/app/centre-supervisor-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_3qcE6PNpsY from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_patch_hash=y2bjtiada6cj3qvvllbuctyaka_@babel+parser@7.26.3_magica_wa2m6qrajreerz5x42wrh4cqpe/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_eNhSKf90o8 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Xmlc8WwSfn from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_W2E6c3WjkJ from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typesc_nbrpmgnntr27mpnb2bpdnwzm6m/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import trpc_IJvhwZlfLl from "/app/backend/plugins/trpc.ts";
import chartjs_1wC9lAM0Tp from "/app/centre-supervisor-nuxt/plugins/chartjs.ts";
import hookVueError_FUSNmNXceB from "/app/centre-supervisor-nuxt/plugins/hookVueError.ts";
export default [
  revive_payload_client_iDETYPv4MC,
  unhead_b2UWYo84sl,
  router_Nh8L56SyFT,
  payload_client_ZBlUIYAmBb,
  navigation_repaint_client_3lyIqAzLiu,
  check_outdated_build_client_kbriyrF8Hi,
  chunk_reload_client_sSDI4m3RX4,
  plugin_vue3_f7Kkr6tkyO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_y2bXzdP3Kh,
  plugin_p9KGT4uXma,
  primevue_plugin_egKpok8Auk,
  plugin_client_3qcE6PNpsY,
  switch_locale_path_ssr_eNhSKf90o8,
  i18n_Xmlc8WwSfn,
  plugin_W2E6c3WjkJ,
  trpc_IJvhwZlfLl,
  chartjs_1wC9lAM0Tp,
  hookVueError_FUSNmNXceB
]