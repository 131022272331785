<script setup lang='ts'>

import type { ButtonHTMLAttributes } from 'vue'

const props = withDefaults(defineProps<{
    show: boolean
    header: string
    solidHeader?: boolean

    static?: boolean
    message?: string

    cancelButtonId?: string

    canConfirm?: boolean
    confirmButtonId?: string
    confirmButtonType?: ButtonHTMLAttributes['type']
    confirmText?: string
    disableConfirm?: boolean
}>(), {
    solidHeader: undefined,

    static: undefined,
    message: undefined,

    cancelButtonId: undefined,

    canConfirm: undefined,
    confirmButtonId: undefined,
    confirmButtonType: 'button',
    confirmText: undefined,
    disableConfirm: undefined
})

const emit = defineEmits<{
    cancel: []
    close: []
    confirm: []
}>()

onMounted(() => {
    const { confirmButtonId, cancelButtonId } = props

    if (confirmButtonId) {
        if (!confirmButtonId.startsWith('confirm-')) {
            throw new Error('Expected `confirm-button-id` in `<ConfirmationDialogue>` to start with "confirm-"')
        }

        if (confirmButtonId.includes(' ')) {
            throw new Error('Expected `confirm-button-id` to contain no empty space')
        }
    }

    if (cancelButtonId) {
        if (!cancelButtonId.startsWith('cancel-')) {
            throw new Error('Expected `cancel-button-id` in `<ConfirmationDialogue>` to start with "cancel-"')
        }

        if (cancelButtonId.includes(' ')) {
            throw new Error('Expected `cancel-button-id` to contain no empty space')
        }
    }

    if (confirmButtonId && cancelButtonId) {
        const confirmSuffix = confirmButtonId.replace(/^confirm-/, '')
        const cancelSuffix = cancelButtonId.replace(/^cancel-/, '')

        if (confirmSuffix !== cancelSuffix) {
            throw new Error(`Expected \`confirm-button-id\` and \`cancel-button-id\` suffix to match, received "${confirmSuffix}" and "${cancelSuffix}"`)
        }
    }
})

</script>

<template>
    <BaseModal
        :static='static'
        :show='show'
        @close='emit("close")'
    >
        <BasePanel class='min-w-72 max-w-prose overflow-visible'>
            <div class='grid items-center gap-4 rounded-t bg-white p-8'>
                <div
                    v-if='solidHeader'
                    class='-m-2 mb-2 flex h-16 items-center rounded-md bg-gradient-to-b from-gray-500 to-gray-700 px-4 py-2 font-bold text-white'
                >
                    {{ header }}
                </div>

                <template v-else>
                    <h1 class='text-xl font-semibold leading-6 tracking-wider text-gray-700'>
                        {{ header }}
                    </h1>
                    <hr>
                </template>

                <p v-if='message'>
                    {{ message }}
                </p>

                <slot v-else />
            </div>

            <div class='grid grid-flow-col justify-end gap-4 rounded-b bg-gray-200 p-4'>
                <DarkSolidButton
                    v-if='cancelButtonId'
                    :id='cancelButtonId'
                    type='reset'
                    tabindex='1'
                    @click='emit("cancel")'
                >
                    {{ $t('general_terms.cancel') }}
                </DarkSolidButton>

                <GreenSolidButton
                    v-if='canConfirm !== false && confirmButtonId'
                    :id='confirmButtonId'
                    :type='confirmButtonType'
                    :disabled='disableConfirm'
                    tabindex='2'
                    @click='emit("confirm")'
                >
                    {{ $t(`general_terms.${confirmText || 'confirm'}`) }}
                </GreenSolidButton>
            </div>
        </BasePanel>
    </BaseModal>
</template>
